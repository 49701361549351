import React from "react";
import butterfly from '../img/butterfly.jpg'

const Vision = () => {
  return (<div>

<br/>
<br/>
<br/>
<br/>

<div className="container">
<div className="p-4">
        <div className="row rounded-5 p-3" style={{borderLeftStyle:"solid", borderRightStyle:"solid"}}>
          <div className="col-sm col-md-10 col-lg-10 pathway-extreme text-center align-self-center">
            <p className="m-0 comfortaa">the vision</p>
          <p className="p-3 ">pioneer the future of business through the seamless integration of digital transformation and AI-driven strategies.<br/>
         Our mission is to unlock the full potential of data and automation, driving efficiency, personalization, and sustainable growth for our clients</p>
          </div>
          <div className="col-sm col-md-2 col-lg-2 align-self-center p-md-0 p-lg-3">
              <img src={butterfly} className="img-fluid rounded-5 " style={{boxShadow: "rgb(122,42,1) 0px 22px 70px 4px"}} />
          </div>
        </div>
      </div>
</div>
   
  </div>)
};

export default Vision;
