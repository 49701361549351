import React from "react";

const ChatEase = () => {
  return (<div>
    
<br/>
<br/>
<br/>
    <h1>Make your website talk!</h1>
  </div>)
};

export default ChatEase;
