import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect } from "react";

//components
import Main from './components/main';


import AOS from "aos";
import 'aos/dist/aos.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/src/collapse.js";



import ClinicFlow  from "./components/clinicFlow";
import About from "./components/about";
import OrderEase from "./components/orderEase";
import Onboarding from "./components/onboarding";
import NotFound from "./components/notFound";
import Products from "./components/products";
import Development from "./components/development";
import Contact from "./components/contact";
import Apps from "./components/apps";
import Pricing from "./components/pricing";
import OnboardSuccess from "./stripe/onboardSuccess";
import Services from "./components/services";
import ChatEase from "./components/chatEase";
import Blogs from "./components/blogs";
import What from "./components/what";
import Bazar from "./components/bazar";
import Digital from "./components/digital";
import Why from "./components/why";
import Vision from "./components/vision";
import Addresses from "./components/adresses";
import SironaWellLandingPage from "./components/SironaWellLandingPage";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {

  useEffect(() => {

    AOS.init({ duration: 1000 });
    AOS.refresh();

    }, []);


  return (
    <div>
     <BrowserRouter>
      <Routes>
        <Route path="/" element={ <Main/> } />
        <Route path="/onboarding-completed" element={ <OnboardSuccess/> } />
        <Route path="/clinicflow" element={ <ClinicFlow/> } />
        <Route path="/contact" element={ <Contact/> } />
        <Route path="/services" element={ <Services/> } />
        <Route path="/addresses" element={ <Addresses/> } />
        <Route path="/apps" element={ <Apps/> } />
        <Route path="/orderease" element={ <OrderEase/> } />
        <Route path="/register" element={ <Onboarding/> } />
        <Route path="/about" element={ <About/> } />
        <Route path="/products" element={ <Products/> } />
        <Route path="/pricing" element={ <Pricing/> } />
        <Route path="/blogs" element={ <Blogs/> } />
        <Route path="/development" element={ <Development/> } />
        <Route path="/chatease" element={ <ChatEase/> } />
        <Route path="/what" element={ <What/> } />
        <Route path="/why" element={ <Why/> } />
        <Route path="/bazar" element={ <Bazar/> } />
        <Route path="/vision" element={ <Vision/> } />
        <Route path="/digital-transformation" element={ <Digital/> } />
        <Route path="/sironawell" element={ <SironaWellLandingPage/> } />
        <Route path="/policy" element={ <PrivacyPolicy/> } />
        <Route path="*" element={ <NotFound/> } />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
