import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Footer from './components/footer';
//import Navbar from './components/navbar';
import CookieConsent from "react-cookie-consent";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import Navbar2 from './components/navbar2';

const button = {
  backgroundColor: 'white',
};

const decline = {
  backgroundColor: 'white',
  color:"black"
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="myAwesomeCookieName2"
      expires={150}
      declineButtonText="Decline"
      enableDeclineButton={true}
      buttonStyle={button}
      declineButtonStyle ={decline}
    >
    We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept" you consent to our use of cookies.
  </CookieConsent>

<Navbar2/>
<br/>
<br/>
    <App />
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
