import React from "react";
import trans from '../img/transformation.jpg'
import frog from '../img/frog.jpg'
import bird3 from '../img/bird3.jpg'
import monkey from '../img/monkey.jpg'
import monkey3 from '../img/monkey3.jpg'
import monkey2 from '../img/monkey2.jpg'
import monkey4 from '../img/monkey4.jpg'
import arrow from '../img/arrow4.png'
import cat from '../img/cat.jpg'
import leo from '../img/leopard.jpg'
import arrow1 from '../img/arrow3.png'



const Digital = () => {
  return (<div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <div  className="container">

      <div className="p-4">
        <div className="row rounded-5 p-3" style={{borderLeftStyle:"solid", borderRightStyle:"solid"}}>
          <div className="col-sm col-md-10 col-lg-10 pathway-extreme text-center align-self-center" data-aos="fade-up">
            <p className="responsive-font-bazar-intro"> digital innovation is not an upgrade, it's a revolution!</p>
            <p className="responsive-font-bazar-content">reimagining how you operate, connect with customers, and stay competitive in a digital-first world</p>
            <a href="/how" style={{color:"#C74D42"}}><p className="comfortaa responsive-font-bazar-intro m-0">join the revolution and be a transformer!</p></a>
            <br/>
          </div>
          <div className="col-sm col-md-2 col-lg-2 align-self-center p-md-0 p-lg-4" data-aos="fade-down">
              <img src={trans} className="img-fluid rounded-5 " style={{boxShadow: "rgb(198,77,68) 0px 22px 70px 4px"}} />
          </div>
        </div>
      </div>

      <br/>

      <div className="row p-3 justify-content-center">
        <div className="col-md-5 col-lg-5 col-sm text-center p-4 rounded-5" style={{borderBottomStyle:"solid"}}>
          <div  className=" rounded-3 p-lg-0 p-md-0 p-4" data-aos="fade-up">
            <p className="responsive-font-bazar-content pathway-extreme">digital innovation is not just about adopting new technologies, it's a mindset shift that can revolutionize the way you operate</p>
          </div>
        </div>
        <br/>
        <div className="col-1"></div>
       

        <div className="col-md-5 col-lg-5 col-sm text-center p-4 rounded-5" style={{borderBottomStyle:"solid"}}>
          <div  className=" rounded-3 p-lg-0 p-md-0 p-4" data-aos="fade-up">
            <p className="responsive-font-bazar-content pathway-extreme"> It's about leveraging digital tools and insights to streamline processes, enhance customer experiences, and drive growth</p>
          </div>
        </div>
      </div>
<br/>

      <div className="row text-center justify-content-center comfortaa p-3">

        <div className="col-12">
          <h2>transformers</h2>
        </div>

        <div className="col-sm col-md-3 col-lg-3 p-2 p-lg-1 p-md-0 text-center align-self-center rounded-5 m-4" style={{borderTopStyle:"solid", borderBottomStyle:"solid"}} >
        <br/>

          <img src={monkey3} className="img-fluid rounded-5" style={{boxShadow: "rgb(0,0,0) 0px 22px 70px 4px"}}/>
          <p className="p-3 responsive-font-bazar-intro ">reimagining processes</p>
          <p className="p-3">in the digital age, traditional business processes often fall short. They can be slow, rigid, and disconnected. This is where process transformation comes into play.
           It's about harnessing the full potential of technology to reshape how you operate and serve your customers</p>
        </div>

        <br/>

        <div className="col-sm col-md-3 col-lg-3 p-2 p-lg-1 p-md-0 text-center align-self-center m-4 rounded-5" data-aos="fade-down" style={{borderTopStyle:"solid", borderBottomStyle:"solid"}}>
          <br/>
          <img src={monkey4} className="img-fluid rounded-5 " style={{boxShadow: "rgb(240,153,134) 0px 22px 70px 4px"}}/>
          <p className="p-3 responsive-font-bazar-intro ">culture</p>
          <p className="p-3">culture is the heart and soul of any organization. It shapes how we think, act, and respond to challenges, we believe that a culture of innovation is the driving force behind successful digital transformation</p>
        </div>

        <br/>

        <div className="col-sm col-md-3 col-lg-3 p-lg-1 p-2 p-md-0  text-center align-self-center m-4 rounded-5" data-aos="fade-up" style={{borderTopStyle:"solid", borderBottomStyle:"solid"}}>
        <br/>
          <img src={monkey2} className="img-fluid rounded-5 " style={{boxShadow: "rgb(0,0,0) 0px 22px 70px 4px"}} />
        <p className="p-3 responsive-font-bazar-intro ">customer experiences</p>
        <p className="p-3">true measure of success in today's digital landscape lies in exceptional customer experiences, our unwavering commitment to putting the customer at the center of everything we do defines our digital strategy</p>
        </div>
      </div>
    

      
      <br/>

      <div className="row">
        <div className="col-sm col-md-12 col-lg-12 pathway-extreme text-center align-self-center">
          <a href="/how" style={{color:"#00394C"}}>
           <p className="comfortaa responsive-font-bazar-intro m-0">be a transformer</p>
           <img src={arrow1} height="80"/>
           </a>
        </div>
      </div>

    </div>

  </div>)
};

export default Digital;
