import React, { useEffect } from "react";
import '../rotating-card.css'
import mail from '../images/mail.png'
import flip1 from '../images/flip1.png'
import flip2 from '../images/flip2.png'
import linkedin from '../img/lin.png'
import phone from '../images/phone.png'
 


const BusinessCard = (props) => {

useEffect(() => {
    var card = document.getElementById(props.id);
      card.addEventListener('click', function() {
        card.classList.toggle('is-flipped');
    });
},[]);


const Flip = () => {
    var card = document.getElementById(props.id);
    card.classList.toggle('is-flipped');
}

  return (<div>
    
            <div class="card-container">
         <div class="card" id={props.id}>
             <div class="front" style={{backgroundColor:"#F3F7F9"}}>
                 <div >
                     <img className="img-fluid" src={props.photo}/>
                 </div>
                 <div class="content" >
                     <div class="main p-2" >
                        <h3 style={{fontFamily: 'Josefin Slab, serif', fontSize:"1.5em"}}>{props.name}</h3>
                        <p>{props.title}</p>
                        <br></br>
                         <div class="rating">
                            <a style={{color:"black", fontFamily:"Roboto Slab, serif", textDecoration:"none"}} href={"mailto:" + props.mail}>
                                <span>{props.mail}</span> 
                            </a>
                            <br></br>
                            <br></br>
                            <img height="20px" className="mb-1" src={props.flag} />
                            <span style={{color:"black", fontFamily:"Roboto Slab, serif"}} className="p-2">{props.mobile}</span> 
                            <br></br>
                            <a href={props.linkedin} style={{textDecoration:"none", color:"#1D5C9A"}}>
                                <img height="20px" className="mb-1" src={linkedin} /> <span >{props.name}</span>
                            </a>
                        </div>
                     </div>
                     <br></br>
                     <p>{props.resume}</p>
                 </div>
             </div> 

             <div class="back" style={{backgroundColor:"#FCF9D9"}}>
                 <div class="header text-center">
                     <p style={{fontFamily: 'Josefin Slab, serif', fontSize:"1.5em"}}>{props.name}</p>
                     <hr></hr>
                 </div>
                 <div class="content text-center">
                    <p class="profession">{props.title}</p>
                    <p class="text-center">{props.resume}</p>
                </div>
             </div>
         </div> 
    </div> 
    




<br></br>
<br></br>


  </div>)
};

export default BusinessCard;
