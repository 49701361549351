import React from "react";
import Code from "./code";
import agile from '../img/agile2.jpg'
import cloud from '../img/cloud2.png'
import arrow from '../img/arrow4.png'
import arrow5 from '../img/arrow5.png'

const Development = () => {



  return (<div>

    <br></br>
    <br></br>

    <div className="container-fluid">

        <div className="row pt-4 pb-4" style={{backgroundColor:"#F6F6F6"}}>
        <div className="col-sm col-md-8 col-lg-8 text-center p-4 align-self-center">

        <p style={{fontFamily: 'Josefin Slab, serif', fontSize:"2em"}}>
            Our code skills are the foundation for dynamic interactions, intuitive navigation, and personalized experiences
        </p>

        </div>
            <div className="col-sm col-md-4 col-lg-4">
                <Code />
            </div> 
        </div>

        <br></br>
        <br></br>


<div className="container">
  <div className="row">
    <div className="col-12 text-center">
      <p style={{fontFamily: 'Josefin Slab, serif', fontSize:"2em"}}>Solve your business needs using Microsoft Cloud services</p>
    </div>
  </div>
<div className="row text-center">
  <div className="col-sm col-md-3 col-lg-3 p-4" style={{backgroundColor:"#E6E6E6"}}>
    <p style={{fontFamily: 'REM, sans-serif', fontSize:"1.5em"}}>App Development</p>
    <p style={{fontFamily: 'Josefin Slab, serif', fontSize:"1.5em"}}> Enhance user experience, increase engagement, and boost brand visibility with a customized and user-friendly app</p>
    <a href="/apps" className="btn"><span style={{fontFamily: 'Josefin Slab, serif', fontSize:"1.5em"}}>more information</span>
      <img src={arrow} height="50" />
    </a>
  </div>
  <div className="col-sm col-md-3 col-lg-3 p-4" >
    <p style={{fontFamily: 'REM, sans-serif', fontSize:"1.5em"}}>WebApps</p>
    <p style={{fontFamily: 'Josefin Slab, serif', fontSize:"1.5em"}}>Transform your business with cutting-edge web app development,
     drive efficiency, streamline processes, and reach a broader audience</p>
    <a href="/weapps" className="btn"><span style={{fontFamily: 'Josefin Slab, serif', fontSize:"1.5em"}}>more information</span>
      <img src={arrow} height="50" />
    </a>
  </div>
  <div className="col-sm col-md-3 col-lg-3 p-4" style={{backgroundColor:"#0067B8", color:"white"}}>
    <p style={{fontFamily: 'REM, sans-serif', fontSize:"1.5em"}}>Third Part Integrations</p>
    <p style={{fontFamily: 'Josefin Slab, serif', fontSize:"1.5em"}}>Unlock new possibilities with our online integrations development, seamlessly connect systems, 
    enhance productivity, and optimize workflows</p>
    <a href="/integrations" className="btn" style={{color:"white"}}><span style={{fontFamily: 'Josefin Slab, serif', fontSize:"1.5em"}}>more information</span>
      <img src={arrow5} height="50" />
    </a>
  </div>
  <div className="col-sm col-md-3 col-lg-3 p-4" style={{backgroundColor:"#E6E6E6"}}>
    <p style={{fontFamily: 'REM, sans-serif', fontSize:"1.5em"}}>Customized Software</p>
    <p style={{fontFamily: 'Josefin Slab, serif', fontSize:"1.5em"}}> Personalized solutions for your unique needs,
     unlock your company's full potential with our custom software development services</p>
    <a href="/custom" className="btn"><span style={{fontFamily: 'Josefin Slab, serif', fontSize:"1.5em"}}>more information</span>
      <img src={arrow} height="50" />
    </a>
    <p></p>
  </div>
</div>
</div>

<br></br>
<br></br>


        <div className="row p-md-5 p-lg-5" style={{backgroundColor:"#E6E6E6"}}>
        <div  className="col-sm col-md-6 col-lg-6 text-center">
        <img src={cloud} className=" img-fluid p-lg-5 p-md-5" />
      </div>

      <div className="col-sm col-md-6 col-lg-6 p-4 align-self-center text-center" style={{backgroundColor:"#E6E6E6"}} >

        <p style={{fontFamily: 'Josefin Slab, serif', fontSize:"2em"}}>Embrace Azure Cloud Services that provide scalability, reliability, and security to develop applications and services</p>
        <a href="/custom" className="btn">
      <img src={arrow} height="50" />
    </a>
      
      </div>
  </div>

<br></br>
  
  <div className="row">
    <div  className="col-sm col-md-6 col-lg-6">
      <img src={agile} className="img-fluid" />
    </div>

    <div  className="col-sm col-md-6 col-lg-6 text-center p-lg-5 p-md-5 align-self-center">
      <br></br>
    <p style={{fontFamily: 'Josefin Slab, serif', fontSize:"2em"}}>An iterative and incremental approach to software development that emphasizes flexibility, collaboration, and customer feedback throughout the entire development process</p>
        <a href="/custom" className="btn">
          <img src={arrow} height="50" />
        </a>
    </div>
  </div>

  <br></br>

    </div>
  </div>)
};

export default Development;
