import React, { useState, useRef, forwardRef } from "react";
import PhoneInput from 'react-phone-number-input'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'
import axios from "axios";
import { CLIENT_ID, ONBOARDING_REFRESH_URL, ONBOARDING_RETURN_URL, ONBOARDING_URL } from "../constants";
import CircularProgress from '@mui/material/CircularProgress';
import { Checkbox } from "@mui/material";

const Onboarding = () => {

const[name, setName] = useState("");
const[company, setCompany] = useState("");
const[taxNumber, setTaxNumber] = useState("");
const[address, setAddress] = useState("");
const[zipCode, setZipCode] = useState("");
const[city, setCity] = useState("");
const[phone, setPhone] = useState("");
const[mail, setMail] = useState("");

  //conditions
const[isNameMissing, setIsNameMissing] = useState(false);
const[isPhoneMissing, setIsPhoneMissing] = useState(false);
const[isMailMissing, setIsMailMissing] = useState(false);
const[isMailCorrect, setIsMailCorrect] = useState(true);
const[isAddressMissing, setIsAddressMissing] = useState(false);
const[isZipMissing, setIsZipMissing] = useState(false);
const[isTownMissing, setIsTownMissing] = useState(false);
const[isLoading, setIsLoading] = useState(false);
const[isCompanyMissing, setIsCompanyMissing] = useState(false);
const[isTaxNumberMissing, setIsTaxNumberMissing] = useState(false);


  const ValidateInputs = () =>{

    if(company === '' || company === undefined){
      setIsCompanyMissing(true);
      return false;
    }
    setIsCompanyMissing(false);

    if(name === '' || name === undefined){
      setIsNameMissing(true);
      return false;
  }
  setIsNameMissing(false);

    
  if(taxNumber === '' || taxNumber === undefined){
    setIsTaxNumberMissing(true);
    return false;
  }
  setIsTaxNumberMissing(false);
  
  if(address === '' || address === undefined){
    debugger
    setIsAddressMissing(true);
    return false;
  }
  setIsAddressMissing(false);




 
  
  
  if(zipCode === '' || zipCode === undefined){
    setIsZipMissing(true);
    return false;
  }
  setIsZipMissing(false);
  
  if(city === '' || city === undefined){
    setIsTownMissing(true);
    return false;
  }
  setIsTownMissing(false);
  
  if(mail === '' || mail === undefined){
    setIsMailMissing(true);
    return false;
  }
  setIsMailMissing(false);
  
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if(!mail || regex.test(mail) === false){
    setIsMailCorrect(false);
      return false;
  }
  setIsMailCorrect(true);
  
  if(ref.current.value === '' || ref.current.value === undefined){
    setIsPhoneMissing(true);
    return false;
  }
  setIsPhoneMissing(false);

  return true;
  
  }

  const save = () =>{


    var valid = ValidateInputs();


    if(valid){

    setIsLoading(true);


    var model ={
      fullName: name,
      email: mail,
      phone: ref.current.value,
      address: address,
      city: city,
      zipCode: zipCode,
      countryCode: "GB",
      taxNumber: taxNumber,
      company: company,
      clientId: CLIENT_ID, 
      refreshUrl: ONBOARDING_REFRESH_URL,
      returnUrl: ONBOARDING_RETURN_URL
    }
    
    axios({
      method:"post", 
      url: ONBOARDING_URL, 
      data: model
    }).then(response =>{
      console.log(response.data);
      window.location.replace(response.data);
    }).catch(error =>{
      console.log(error);
    });    
  }
}


const setPhoneNumber = () =>{
  ref.current.focus();  
  console.log(ref.current.value);
  //setPhone(ref.current.value);
}

const ref = useRef();

const phoneInput = forwardRef(({ value, onChange },  reff) => (
  <input id="inp" className="form-control" value={value} style={{height:"75px", fontSize:"1.5em"}} ref = {reff}  onChange={onChange}></input>
));
  
  return (<div>

    <br></br>
    <br></br>
    <br></br>
    <br></br>


<div className="container" style={{fontFamily: 'Josefin Slab, serif'}}>
<div className="row ">
      <div className="col-sm col-md-12 col-lg-12">
        <h1>Merchant Onboarding</h1>
        <h5>In the upcoming step, you'll be guided through our Stripe onboarding process, this step is crucial to securely process payments for our services.</h5>
        <hr></hr>
      </div>
    </div>
    <div className="row">
    <div className="col-md-6 col-lg-6 p-2">
            <h4>Company</h4>
            <input type="text" style={{fontSize:"1.7em"}} className="form-control mb-2 p-3"  onChange={event => setCompany(event.target.value)} />
        </div>
        <div className="col-md-6 col-lg-6 p-2">
            <h4>Contact Person</h4>
            <input type="text" style={{fontSize:"1.7em"}} className="form-control mb-2 p-3"  onChange={event => setName(event.target.value)} />
        </div>
        
    </div>

    <div className="row">
    <div className="col-md-6 col-lg-6 p-2">
            <h4>Tax Number</h4>
            <input style={{fontSize:"1.7em"}} type="text" className="form-control mb-2 p-3"  onChange={event => setTaxNumber(event.target.value)} />
        </div>
    <div className="col-md-6 col-lg-6 p-2">
            <h4>Address</h4>
            <input style={{fontSize:"1.7em"}} type="text" className="form-control mb-2 p-3"  onChange={event => setAddress(event.target.value)} />
        </div>
        
    </div>

    <div className="row">
      <div className="col-md-6 col-lg-6 p-2">
          <h4>Zip Code</h4>
          <input type="text" style={{fontSize:"1.7em"}} className="form-control mb-2 p-3" onChange={event => setZipCode(event.target.value)} />
      </div>
      <div className="col-md-6 col-lg-6 p-2">
        <h4>City</h4>
        <input style={{fontSize:"1.7em"}} type="text" className="form-control mb-2 p-3" onChange={event => setCity(event.target.value)} />
      </div>
    </div>

    <div className="row">
      <div className="col-md-6 col-lg-6 p-2">
        <h4>Mail</h4>
        <input  type="email" style={{fontSize:"1.7em"}} className="form-control mb-2 p-3"  onChange={event => setMail(event.target.value)} />

      </div>
        <div className="col-md-6 col-lg-6 p-2">
            <h4>Business Phone Number</h4>
            <PhoneInput 
            international
            inputComponent = {phoneInput}
            style={{fontSize:"1.7em"}}
            countryCallingCodeEditable={false}
            onCountryChange={(value) => {console.log(value)}}
            defaultCountry="GB"
            ref={ref}
            onChange={setPhoneNumber}/> 
           
        </div>
    </div>

    <br></br>

    <div className="row">
      <p>
      SPICA AI is committed to protecting and respecting your privacy, and we will only use your personal information to administer your account and to provide the products and services you requested from us. 
      From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you:

<br />
<br />
      <Checkbox className="mb-1"></Checkbox><span>I agree to receive other communications from SPICA AI.</span>
<br />
<br />

You may unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our <a href="#">Privacy Policy</a>.

By clicking Continue below, you consent to allow SPICA AI to store and process the personal information submitted above to provide you the content requested.
  
      </p>
    </div>

    <div className="row">
      <div className="col-sm col-md-4 col-lg-4">
        <button onClick={() => save()} className="btn rounded-0" style={{backgroundColor:"#00394C", color:"white"}}>
          <h3 className="pt-2">Continue</h3>
        </button>
      </div>
      <div className="col-sm col-md-4 col-lg-4">
      { isLoading && <div className="container text-center" style={{opacity:0.8}}>
        <br></br>
        <br></br>
          <CircularProgress color='warning'/><h3>please wait...</h3>
        </div>
      }
      </div>
      <div className="col-sm col-md-4 col-lg-4"></div>
      <div className="col-sm col-md-4 col-lg-4"></div>
    </div>

    <div className="row">
      <div className="col-12">
      { isCompanyMissing && <h5 className="col alert alert-danger m-1"> company is missing </h5> }
      { isNameMissing && <h5 className="col alert alert-danger m-1">contact person is missing </h5> }
      { isTaxNumberMissing && <h5 className="col alert alert-danger m-1">tax number is missing </h5> }
      { isAddressMissing && <h5 className="col alert alert-danger m-1">address is missing </h5> }
      { isZipMissing && <h5 className="col alert alert-danger m-1">zip code is missing</h5> }
      { isTownMissing && <h5 className="col alert alert-danger m-1"> city is missing</h5> }
      { isMailMissing && <h5 className="col alert alert-danger m-1"> your mail is missing </h5> }
      { isMailCorrect === false && <h5 className="col alert alert-danger m-1"> mail address is not in the right format </h5> }
      { isPhoneMissing && <div className="col alert alert-danger m-1"> <h5>business number is missing</h5> </div> }
      </div>
    </div>


</div>

<br></br>

  </div>)
};

export default Onboarding;
