import React from "react";
import working from '../img/working.png'

const NotFound = () => {
  return (<div style={{minHeight:"600px"}}>
    <br></br>
    <br></br>
    <br></br>
    <div className="container-fluid" >
  <div className="row text-center">
      <div className="col-12 align-self-center p-0" >
        <h1 className="p-4" style={{fontFamily: 'Josefin Slab, serif'}}>Still working on it..</h1>
        <img src={working} height="150px" />
      </div>
  </div>
</div>
    
  </div>)
};

export default NotFound;
