import React from "react";

import eye from '../img/eye.jpg'
import marketing from '../img/marketing.jpg'
import bazar from '../img/bazar.jpg'
import homepage from '../img/webapps.jpg'
import apps from '../img/apps.jpg'
import apps2 from '../img/apps2.jpg'
import arrow from '../img/arrow4.png'
import shopping from '../img/bird.jpg'
import odalis from '../img/odalis.jpg'


const What = () => {
  return (<div>

    <br/>
    <br/>
    <br/>

    <div className="container-fluid">
      <div className="row wrapper p-4 justify-content-center text-center" style={{backgroundImage: `url(${shopping})`, backgroundSize:"cover", backgroundPosition:"20% 20%", fontFamily: 'Josefin Slab, serif'}}>
        <div className="col-sm col-lg-12 col-md-12 align-self-center p-4 content">
          <br/>
          <p className="responsive-font-bazar-intro comfortaa" style={{color:"white"}}>choose the desire product for your business
          </p>
          <a className="btn content" style={{backgroundColor:"#00394C", color:"white", boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px"}} href="/contact"> <h3 className="pt-2"><b>Contact</b></h3> </a>
        </div>
       
      </div>
    <br/>
    </div>

    <div className="container p-5">    
        <div className="row rounded-5 p-3" style={{borderStyle:"solid", borderLeftWidth:"0px"}}>
            <div className="col-sm col-md-9 col-lg-9 text-center align-self-center p-2">
                <h4 className="pathway-extreme"  data-aos="fade-up" ><p className="comfortaa"  style={{fontSize:"40px"}}>baZar</p> a cloud based ordering software for businesses </h4>
                <a href="/bazar">
                    <img  src={arrow} height="50"/>
                </a>
            </div>
            <div className="col-sm col-md-3 col-lg-3 text-center">
                <img  data-aos="fade-up"  src={bazar} className="img-fluid rounded-5" style={{boxShadow: "rgba(244,160,70) 0px 22px 70px 4px"}}/>
            </div>
        </div>

        <br/>
        
        <div className="row rounded-5 p-3" style={{borderStyle:"solid", borderRightWidth:"0px"}}>
            <div className="col-sm col-md-3 col-lg-3 text-center order-2 order-md-1 order-lg-2">
                <img data-aos="fade-up" src={eye} className="img-fluid rounded-5" style={{boxShadow: "rgba(118, 11, 65) 0px 22px 70px 4px"}}/>
            </div>
            <div className="col-sm col-md-9 col-lg-9 text-center align-self-center p-2  order-1 order-md-2 order-lg-2">
                <h4 data-aos="fade-up" className="pathway-extreme"><p  style={{fontSize:"40px"}} className="comfortaa">the eYe</p> unlock deeper insights into your sales with an AI cloud-based analytics system </h4>
                <a href="/theeye">
                    <img  src={arrow} height="50"/>
                </a>
            </div>
        </div>

        <br/>

        <div className="row rounded-5 p-3" style={{borderStyle:"solid", borderLeftWidth:"0px"}}>
            <div className="col-sm col-md-9 col-lg-9 p-2 text-center align-self-center">
                <h4 data-aos="fade-up" className="pathway-extreme"><p className="comfortaa" style={{fontSize:"40px"}}>Hannibal</p> 
                automated campaigns for your customers generated by an AI cloud-based marketing software
                </h4>
                <a href="/takecare">
                    <img  src={arrow} height="50"/>
                </a>
            </div>
            <div className="col-sm col-md-3 col-lg-3 text-center">
                <img data-aos="fade-up" src={marketing} className="img-fluid rounded-5" style={{boxShadow: "rgba(4, 87, 79) 0px 22px 70px 4px"}}/>
            </div>
        </div>

        <br/>

        <div className="row rounded-5 p-3" style={{borderStyle:"solid", borderRightWidth:"0px"}}>
          
            <div className="col-sm col-md-3 col-lg-3 text-center order-2 order-md-1 order-lg-2">
                <img  data-aos="fade-up"  src={odalis} className="img-fluid rounded-5" style={{boxShadow: "rgba(244,160,70) 0px 22px 70px 4px"}}/>
            </div>
            <div className="col-sm col-md-9 col-lg-9 text-center align-self-center p-2 order-1 order-md-2 order-lg-2">
                <h4 className="pathway-extreme"  data-aos="fade-up" ><h3 className="comfortaa"  style={{fontSize:"40px"}}>Odalis</h3> AI chatbox service for your website to improve user engagement and provide customer support</h4>
                <a href="/webapps">
                    <img  src={arrow} height="50"/>
                </a>
            </div>
        </div>

        <br/>

    </div>

  </div>)
};

export default What;
