import axios from "axios";
import React, { useEffect } from "react";
import { CREATE_CUSTOMER_URL } from "../constants";

const OnboardSuccess = () => {

    //create the customer object for the new created connected account

    useEffect(()=> {
        axios({
            method:"post", 
            url: CREATE_CUSTOMER_URL, 
            data:{
                id:"id", 
                name:"name", 
                mail:"mail"
            }
        }).then(response => {
            console.log(response.data);
        }).catch(error => {
            console.log(error);
        });
    },[]);

  return (<div>

<br></br>
<br></br>
<br></br>
<br></br>
    <h2>Your account is created</h2>

  </div>)
};

export default OnboardSuccess;
