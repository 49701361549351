import React from "react";

const Blogs = () => {
  return (<div>


<br/>
<br/>
<br/>
<br/>

Subscribe to our Blog Posts

  </div>)
};

export default Blogs;
