import React from "react";
import '../call.css'

const CallMail = () => {
  return (<div>

<div type="button" class="email-bt">
    <div class="text-call">
      <a href='tel:+4552819553'>
        <i class="fa fa-phone" aria-hidden="true"></i>
      </a>
    </div>
</div>
<div type="button" class="text-bt">
    <div class="text-call"><a href='mailto: amar@spicaai.com'>
        <i class="fa fa-envelope" aria-hidden="true"></i>
        </a>
    </div>
</div>


  </div>)
};

export default CallMail;
