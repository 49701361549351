

import React from "react";

import '../sirona.css'

const PrivacyPolicy = () => {

return (<div>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <div className="container sirona-container rounded-4">
    <div className="row align-items-center">
      <div className="col-sm ol-md-6 col-lg-6 background-div rounded-4"></div>
      <div className="col-sm ol-md-6 col-lg-6 ">
        <div className="container p-4">
          <div className="col-12 shadow p-4 rounded-4 sirona-form">
            <p>SPICA AI Privay Policy</p>
            <p>Please click the link below to download. Thank you!!</p>
            <hr/>
            <a className="btn rounded-2 p-3 m-3 comfortaa"  
            style={{fontSize:"20px", backgroundColor:"#E9A032", color:"white", 
            boxShadow: "rgb(233, 160, 50) 0px 20px 30px -5px"}} href="https://spicaai.blob.core.windows.net/legal/SPICA AI Privacy Policy.pdf">Download Our Privacy Policy</a>

          </div>
        </div>
      </div>
    </div>
    </div>
    <br/>
    <br/>
  </div>)
};

export default PrivacyPolicy;
