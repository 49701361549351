import React from "react"
import logo from '../img/logo5.png'


import '../footer.css'

const Footer = () => {
  return (<div className="container-fluid p-3" style={{fontFamily:"Roboto Slab, serif"}}>
    <div className="row rounded-5" style={{borderTopStyle:"solid"}}>
        <div className="col-12">
    <br/>

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.1/css/all.min.css"/>
<footer className=" footer text-center" style={{color:"black"}}>
    <div >
        <div className="container">
            <div className="footer-border-bottom ">
                <div className="row">
                    <div className="col-lg-4 col-xl-4 align-self-center">
                        <h5 style={{fontFamily:"Roboto Slab, serif", color:"black"}}>Sign up for newsletters and updates</h5>
                    </div>
                    <div className="col-lg-8 col-xl-8">
                        <form className="d-flex flex-row mb-2 p-1 bg-white input-group">
                            <input type="email" className="form-control rounded-0" style={{borderStyle:"solid", borderColor:"black", borderWidth:"1px"}} placeholder="you@yoursite.com" />
                            <button className="btn rounded-0 flex-shrink-0" type="submit" style={{backgroundColor:"#00394C", color:"white"}}>Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 col-lg-3 my-5">
                    <div className="mb-4">
                        <img src={logo} height="200px" title="Avinya Solutions" alt="" />
                    </div>
                    <div className="mb-4"></div>
                </div>
                <div className="col-sm-6 col-lg-2 my-4">
                    <h5 className=" h6 mb-4" style={{color:"black"}}><b>Customer</b></h5>
                    <ul className="list-unstyled footer-links">
                        <li>
                            <a style={{color:"black"}} href="#">Get Corpo</a>
                        </li>
                        <li>
                            <a style={{color:"black"}} href="#">Documentation</a>
                        </li>
                        <li>
                            <a style={{color:"black"}} href="#">Help center</a>
                        </li>
                        <li>
                            <a style={{color:"black"}} href="#">Pricing</a>
                        </li>
                        <li>
                            <a style={{color:"black"}} href="#">Contact us</a>
                        </li>
                    </ul>
                </div>
                <div className="col-sm-6 col-lg-2 my-4">
                    <h5 className=" h6 mb-4" style={{color:"black"}}><b>Product</b></h5>
                    <ul className="list-unstyled footer-links">
                        <li>
                            <a style={{color:"black"}} href="#">Portfolio</a>
                        </li>
                        <li>
                            <a style={{color:"black"}} href="#">Blog</a>
                        </li>
                        <li>
                            <a style={{color:"black"}} href="#">Join our team</a>
                        </li>
                        <li>
                            <a style={{color:"black"}} href="#">Elements</a>
                        </li>
                        <li>
                            <a style={{color:"black"}} href="#">Products</a>
                        </li>
                    </ul>
                </div>
                <div className="col-sm-6 col-lg-2 my-4">
                    <h5 className=" h6 mb-4" style={{color:"black"}}><b>Company</b></h5>
                    <ul className="list-unstyled footer-links">
                        <li>
                            <a style={{color:"black"}} href="#">About</a>
                        </li>
                        <li>
                            <a style={{color:"black"}} href="/addresses">Addresses</a>
                        </li>
                        <li>
                            <a style={{color:"black"}} href="#">Legal &amp; Security</a>
                        </li>
                        <li>
                            <a style={{color:"black"}} href="/policy">Privacy Policy</a>
                        </li>
                        <li>
                            <a style={{color:"black"}} href="#">We're hiring!</a>
                        </li>
                    </ul>
                </div>

                <div className="col-sm-6 col-lg-3 my-4">
                    <h5 className="h6 mb-4" style={{color:"black"}}><b>Technology Partners</b></h5>
                    <ul className="list-unstyled  footer-links">
                        <li>
                            <a style={{color:"black"}} target="_blank" href="https://www.stripe.com">Stripe</a>
                        </li>
                        <li>
                            <a style={{color:"black"}} target="_blank" href="https://www.klarna.com">Klarna</a>
                        </li>
                        <li>
                            <a style={{color:"black"}} target="_blank" href="https://www.swedbank.se">Swedbank</a>
                        </li>
                        <li>
                            <a style={{color:"black"}} target="_blank" href="https://www.nordea.dk">Nordea (Denmark)</a>
                        </li>
                        <li>
                            <a style={{color:"black"}} target="_blank" href="https://danskebank.com">Danske Bank</a>
                        </li>
                    </ul>
                </div>
               
            </div>
        </div>
    </div>
    <div className="footer-bottom footer-border-top light py-3">
        <div className="container text-center">
            <p className="m-0" style={{color:"black"}}>© 2023 Spica AI<a href="#" className="text-reset"></a></p>
            <br/>
            <div className=" footer-socila-icon">
                        <a href="#"><span className="fab fa-facebook-f"></span></a>
                        <a href="#"><span className="fab fa-twitter"></span></a>
                        <a href="#"><span className="fab fa-instagram"></span></a>
                        <a href="#"><span className="fab fa-linkedin-in"></span></a>
                    </div>
        </div>
    </div>
</footer>

</div>
    </div>

  </div>)
};

export default Footer;
