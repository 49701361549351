import React, { useEffect } from "react";
import '../navbar2.css'

import uk from '../img/uk.svg'
import dk from '../img/dk.svg'
import sw from '../img/sw.svg'
import uae from '../img/uae.svg'
import logo3 from '../img/logo5.png'

const Navbar2 = () => {

  return (<div>
    <nav className="navbar navbar-expand-lg shadow-lg fixed-top" style={{backgroundColor:"white", color:"black"}}>
  <div className="container p-2 comfortaa">
    <a href="/" className="p-0"><img style={{height:"90px"}} src={logo3} /></a>
    <button className="navbar-toggler" style={{border:"none", outline:"none"}} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" 
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">

      <li className="nav-item">
          <a className="nav-link m-1 p-4" style={{color:"black", fontSize:"17px"}} aria-current="page" href="/what">what</a>
        </li>

        <li className="nav-item">
          <a className="nav-link m-1 p-4" style={{color:"black",  fontSize:"17px"}} aria-current="page" href="/why">why</a>
        </li>

        <li className="nav-item">
          <a className="nav-link m-1 p-4" style={{color:"black", fontSize:"17px"}} aria-current="page">how</a>
        </li>
      

        <li className="nav-item">
          <a href="/digital-transformation" className="nav-link m-1 p-4" style={{color:"black", fontSize:"17px"}} aria-current="page">go AI</a>
        </li>


        <li className="nav-item">
          <a href="/contact" className="nav-link m-1 p-4" style={{color:"black", fontSize:"17px"}} aria-current="page">contact</a>
        </li>
        <li className="nav-item">
          <a href="/sironawell" className="nav-link m-1 p-4" style={{color:"black", fontSize:"17px"}} aria-current="page">SironaWell</a>
        </li>

       <li className="nav-item dropdown">
          <span className="dropdown" style={{paddingLeft:"20px"}}>
            <img src={uk} height="30px" className="mt-4 "/>
          </span>
          <ul className="dropdown-menu">
            <li>
              <a className="dropdown-item" href="/#">
                <img src={uk} height="30px"/>
                <span>&nbsp; United Kingdom</span>
              </a>
              <a className="dropdown-item" href="/#">
                <img src={sw} height="30px"/>
                <span>&nbsp;Sweden</span>
              </a>
              <a className="dropdown-item" href="/#">
                <img src={dk} height="30px"/>
                <span>&nbsp;Denmark</span>
              </a>
              <a className="dropdown-item" href="/#">
                <img src={uae} height="30px"/>
                <span>&nbsp;UAE</span>
              </a>
            </li>
          </ul>
       </li>
      </ul>

      <br/>
      <a className="btn p-3 rounded-2 m-3" style={{borderStyle:"solid", marginRight:"5px", backgroundColor:"transparent", 
      borderColor:"black", borderWidth:"1px", color:"black"}} href="#"><b>Login</b></a>

      <a className="btn rounded-2 p-3 m-3 comfortaa"  
      style={{fontSize:"20px", backgroundColor:"#E9A032", color:"white", 
      boxShadow: "rgb(233, 160, 50) 0px 20px 30px -5px"}} href="/register">Sign up</a>

      <br/>
      <br/>
    </div>
  </div>
</nav>

  </div>)
};

export default Navbar2;
