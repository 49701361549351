import React from "react";
import shopping from '../img/bags.jpg'
import products from '../img/products.jpg'
import odalis from '../img/odalis.jpg'
import history from "../img/history.jpg";
import social from '../img/royal.jpg'
import noti from '../img/noti.jpg'
import money from '../img/money.jpg'
import list from '../img/list.jpg'
import admin from '../img/admin.jpg'
import cloths from '../img/cloths.jpg'

import '../fonts.css'
import '../orderEaseShop.css'


const Bazar = () => {
  return (<div>

    <br/>
    <br/>
    
    <div className="container-fluid">
      <div className="row wrapper p-4 justify-content-center text-center" style={{backgroundImage: `url(${cloths})`, backgroundSize:"cover", fontFamily: 'Josefin Slab, serif'}}>
        <div className="overlay"></div>
        <div className="col-sm col-lg-4 col-md-4 align-self-center p-4 content">
            <br/>
            <p className="responsive-font-bazar-intro comfortaa" style={{color:"white"}}>get unrestricted access to every feature and tool baZar has to offer
            </p>
            <a className="btn content" style={{backgroundColor:"#00394C", color:"white", boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px"}} href="/pricing"> <h4 className="pt-2"><b>Start Now</b></h4> </a>
        </div>
        <div className="col-sm col-lg-6 col-md-6"></div>
    </div>
    <br/>
    
    <div className="row p-3 justify-content-center">
      <div  className="col-sm col-md-4 col-lg-3 align-self-center p-lg-5 p-md-5">
        <img src={products} className=" rounded-5 img-fluid" style={{boxShadow: "rgba(245, 203, 66) 0px 22px 70px 4px"}} />
      </div>
      <div className="col-sm col-md-7 col-lg-7 pt-lg-5 pt-md-5 align-self-center p-5" >
        <div className="row">
          <div className="col-12 rounded-5 p-4 text-center" style={{borderStyle:"solid", borderColor:"#f5cb42", borderLeftWidth:"0px"}}>
          <p className="comfortaa responsive-font-bazar-intro">Display Your Creations</p><p className="responsive-font-bazar-content comfortaa">baZar offers a dynamic stage for your products to take the spotlight</p>
          </div>
        </div>
    
      </div>
    </div>

    <div className="row p-3 justify-content-center">
    <div className="col-sm col-md-7 col-lg-7 pt-lg-5 pt-md-5 align-self-center p-5 order-2 order-lg-1 order-md-1" >
        <div className="row">
          <div className="col-12 rounded-5 p-4 text-center" style={{borderStyle:"solid", borderColor:"#3C6D6A", borderRightWidth:"0px"}}>
          <p className="comfortaa responsive-font-bazar-intro">Ask Odalis</p><p className="responsive-font-bazar-content comfortaa">Odalis place orders and helps when customers visit your baZar</p>
          </div>
        </div>
      </div>
      <div  className="col-sm col-md-4 col-lg-3 align-self-center p-lg-5 p-md-5 order-1 order-lg-2 order-md-2">
        <img src={odalis} className=" rounded-5 img-fluid" style={{boxShadow: "rgba(254,181,89) 0px 22px 70px 4px"}} />
      </div>
    </div>

    <div className="row text-center justify-content-center rounded-5 p-lg-5 pathway-extreme" >

      <div className="col-sm col-md-3 col-lg-3 align-self-center rounded-5 p-5 m-1" style={{borderBottomStyle:"solid", borderTopStyle:"solid", borderWidth:"1.5px"}}>
          <p style={{color:"#00394C", fontSize:"25px"}}>Order Confirmation</p>
          <br/>
          <img className="img-fluid rounded-5" src={noti}  />
          <p className="pt-5">ensures your customers receive instant, accurate, and reassuring order confirmations</p>
      </div>

      <div className="col-sm col-md-3 col-lg-3 align-self-center rounded-5 p-5 m-1" style={{borderBottomStyle:"solid", borderTopStyle:"solid", borderWidth:"1.5px"}}>
        <p  style={{color:"#00394C", fontSize:"25px"}}>Online Payment</p>
        <br/>
        <img className="img-fluid rounded-5" src={money} />
        <p className="pt-5">Experience the future of online payments with our user-friendly platform, offering a range of payment options</p>
      </div>

      <div className="col-sm col-md-3 col-lg-3 align-self-center rounded-5 p-5 m-1" style={{borderBottomStyle:"solid", borderTopStyle:"solid", borderWidth:"1.5px"}}>
        <p style={{color:"#00394C", fontSize:"25px"}}>Order Stack</p>
        <br/>
        <img className="img-fluid rounded-5" src={list}  />
        <p className="pt-5">Simplify your business operations and keep track of all your orders efficiently, baZar offers a comprehensive solution for managing orders, ensuring smooth operations</p>
      </div>

      <div className="col-sm col-md-3 col-lg-3 align-self-center rounded-5 p-5 m-1" style={{borderBottomStyle:"solid", borderTopStyle:"solid", borderWidth:"1.5px"}}>
          <p style={{color:"#00394C", fontSize:"25px"}}>Social Media</p>
          <br/>
          <img className="img-fluid rounded-5" src={social}  />
          <p className="pt-5">Customers can share your products across social media platforms, to reach a wider audience and maximize your online presence and drive sales</p>
      </div>

      <div className="col-sm col-md-3 col-lg-3 align-self-center rounded-5 p-5 m-1" style={{borderBottomStyle:"solid", borderTopStyle:"solid", borderWidth:"1.5px"}}>
          <p style={{color:"#00394C", fontSize:"25px"}}>Order History</p>
          <br/>
          <img className="img-fluid rounded-5" src={history}  />
          <p className="pt-5">Customer gain easy access to their order records and history to track purchases and reorder their favorite items</p>
      </div>

      <div className="col-sm col-md-3 col-lg-3 align-self-center rounded-5 p-5 m-1" style={{borderBottomStyle:"solid", borderTopStyle:"solid", borderWidth:"1.5px"}}>
          <p style={{color:"#00394C", fontSize:"25px"}}>Administration Portal</p>
          <br/>
          <img className="img-fluid rounded-5" src={admin}  />
          <p className="pt-5">provides you with the tools you need to manage, update, and optimize your product offerings efficiently</p>
      </div>
      
    </div>
    <br/>

    <div className="row pathway-extreme">
        <div className="col-12 text-center">
            <p>Discover the full range of features and capabilities baZar has to offer. <br/>
                Contact us today to learn more and explore how we can tailor baZar to meet your specific needs</p>
          <a className="btn content" style={{backgroundColor:"#00394C", color:"white", boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px"}} href="/contact"> <h4 className="pt-1">Contact</h4> </a>
        </div>
    </div>
  </div>

    
  </div>)
};

export default Bazar;
