import React from "react";
import ui from '../img/concept5.jpg'
import layers from '../img/app-layers.jpg'
import arrow from '../img/arrow4.png'
import android from '../img/android.png'
import apple from '../img/apple.png'
import layers2 from '../img/layers.png'


const Apps = () => {
  return (<div>

    <br></br>
    <br></br>

<div className="container-fuild">
    <div className="row m-0" style={{backgroundColor:"#C0E3FF"}}>
        <div className="col-md-6 col-lg-6 col-sm">
            <br></br>
            <img className="img-fluid" src={ui} />
        </div>
        <div className="col-md-6 col-lg-6 col-sm align-self-center text-center p-4">
            <p style={{fontFamily: 'Josefin Slab, serif', fontSize:"2em"}}>From concept to execution, we ensure a seamless user experience and high-performance mobile application</p>  
            <a href="/apps" className="btn">
                <img src={arrow} height="50" />
            </a>   
        </div>
    </div>

    <div className="row m-0" >
    <div className="col-md-6 col-lg-6 col-sm align-self-center text-center p-4">
            <p style={{fontFamily: 'Josefin Slab, serif', fontSize:"2em"}}>Developing app layers to includes a comprehensive solution to build a robust and user-friendly mobile application</p>     
            <a href="/apps" className="btn">
                <img src={arrow} height="50" />
            </a>
        </div>
        <div className="col-md-6 col-lg-6 col-sm">
            <img className="img-fluid" src={layers} />
        </div>
       
    </div>

</div>

<div className="container-fluid p-4" style={{backgroundColor:"#F1F1F3"}}>
    
<div className="row m-0 text-center" >
        <div className="col-12">
            <img height="100" className="m-4" src={android} />
            <img height="100" className="m-4" src={apple} />
        </div>
    </div> 


    <div className="row text-center">
    <div className="col-12">
        <p  style={{fontFamily: 'Josefin Slab, serif', fontSize:"2em"}}>Delivering high-quality, feature-rich, and scalable mobile applications that run seamlessly on both Android and iOS devices</p>     
        <a href="/apps" className="btn">
            <img src={arrow} height="50" />
        </a>
    </div></div> 
</div>

  </div>)
};


export default Apps;
