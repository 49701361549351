import React, { useEffect } from "react";
import "bootstrap/js/src/carousel.js";
import next from '../img/next.svg'
import prev from '../img/prev.svg'

const Gaps = () => {
  return (<div>
    <div id="carouselExampleAutoplaying" class="carousel slide pathway-extreme" data-bs-ride="carousel">
<div className="carousel-inner"  style={{height:"320px"}}>

<div className="carousel-item active" data-bs-interval="10000">
    <div className="container">
      <div className="row text-center justify-content-center align-items-stretch d-flex">
      
        <div className="col-sm col-md-7 col-lg-7 pt-5 align-self-center">
            <h2 style={{color:"#642D67"}} className="yuji">1</h2>
      <h3 style={{color:"#642D67"}}  className="comfortaa">your valuable business goals</h3>


                <p className="comfortaa p-4 p-lg-0 p-md-0"> 
                business goals are specific, measurable objectives that a company aims to achieve to drive its growth, success, and alignment with its mission and vision. 
                Identifying these goals involves a process of assessing the organization's current state, and defining key areas for improvement
                </p>

        </div>
      </div>
    </div> 
  </div>

  <div className="carousel-item" data-bs-interval="10000">
    <div className="container">
      <div className="row text-center justify-content-center">
       
        <div className="col-sm col-md-7 col-lg-7 align-self-center pt-5 ">
        <h2 style={{color:"#642D67"}}  className="yuji">2</h2>

        <h3 style={{color:"#642D67"}}  className="comfortaa">your SMART business objectives</h3>


                <p className="comfortaa p-4 p-lg-0 p-md-0">
                SMART objectives are a way to operationalize and execute your business goals, provide a detailed and actionable plan for achieving those goals
                </p>

        </div>
      </div>
    </div> 
  </div>

  <div className="carousel-item" data-bs-interval="10000">
    <div className="container">
      <div className="row text-center justify-content-center">
        
        <div className="col-sm col-md-7 col-lg-7 align-self-center pt-5" style={{color:"black"}}>
        <h2 style={{color:"#642D67"}}  className="yuji">3</h2>

        <h3 style={{color:"#642D67"}} >a digital transformation strategy</h3>
 
            <p className="comfortaa p-4 p-lg-0 p-md-0">a digital transformation strategy is a comprehensive plan that leverages technology to reshape and optimize an organization's 
            operations, processes, and services. The strategy is defined by SMART objectives that align with the organization's business goals
            </p>
        </div>
      </div>
    </div>
  </div>
    
      </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
    <img src={prev} height="60px" />
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
  <img src={next} height="60px" />

  </button>
</div>
  </div>)
};

export default Gaps;
