import React from "react";
import bg from '../img/pricingbg.jpg'
import ProductCard from "./productCard";
import booking from '../img/booking.jpg'
import order from '../img/order.jpg'
import clinic from '../img/clinicflow.jpg'
import payment from '../img/payment.jpg'
import integrations from '../img/integrations.jpg'
import apps from '../img/app-layers.jpg'
import responsive from '../img/responsive.jpg'


const Services = () => {
  return (<div>
    <br/>
    <br/>
    <br/>


<div className="container">
  <div className="row text-center">
    <div className="col-12 p-4" style={{fontFamily: 'Josefin Slab, serif', fontSize:"2em"}}>
    <p>Our core strength lies in creating tailor-made software solutions designed to address your business challenges and opportunities</p>
      
    </div>
  </div>
  <div className="row justify-content-center ">
    
      <div className="col-sm col-lg-4 col-md-6 d-flex align-items-stretch">
      <ProductCard image={payment} 
      name="Payment Solutions" 
      href="/orderease"
      buttonText="Read More"
      short="Efficient, and flexible payment processing is vital to the success of your business, we offer a comprehensive suite of payment solutions designed to simplify transactions, enhance customer experiences, and boost your bottom line."/>
      </div>  

      <div className="col-sm col-lg-4 col-md-6 d-flex align-items-stretch">
        <ProductCard image={responsive} name="WebApps" 
        href="#"
        buttonText="Read More"
         short="In the digital age, your web presence matters, we specialize in crafting responsive, user-friendly, and scalable web applications that engage your audience and drive results." />
      </div>  

      <div className="col-sm col-lg-4 col-md-6 d-flex align-items-stretch">
        <ProductCard image={apps} 
        name="App Development" 
        href="#"
        buttonText="Read More"
        bottomSpace = {<br/>}
        short="Extend your reach to a mobile audience with our mobile app development services, we create sleek and functional apps for iOS and Android, ensuring a seamless user experience and top-notch performance." />
      </div>  
  </div>
</div>
<br/>
<br/>
<br/>

  </div>)
};

export default Services;
