import React from "react";

import code from '../images/code.jpg'
import aviation from '../images/aviation.jpg'
import protection from '../images/protection.jpg'
import net from '../images/net.jpg'
import amar from '../img/ama3.png'
import gabriel from '../img/gabriel.jpg'
import chris from '../images/chris.jpeg'
import ahmed from '../images/ahmed.jpeg'
import denmark from '../images/dk.png'
import uk from '../images/uk.png'
import sweden from '../img/sweden.png'
  
import BusinessCard from "./BusinessCard";
import Addresses from "./adresses";

const About = () => {
  return (

    <div className="container">
      <div className="row" style={{fontFamily:"REM, sans-serif"}}>
  <div className="col-sm col-md- 6 col-lg-6">
    <h5><b>Denmark</b></h5>
    <hr/>
    <h6>SPICA AI</h6>
    <h6>Røjlehaven 77</h6>
    <h6>2630 Taastrup</h6>
    <h6>CVR-nummer 38057561</h6>
    <br/>
    <p><b>Contact Information</b></p>
    <h6>Amar Al-Soudi</h6>
    <h6>amar@spicaai.com</h6>
    <h6>+45 52 81 95 53</h6>
    <h6><a href="https://www.linkedin.com/in/amar-al-soudi">LinkedIn</a></h6>
    <h6><a href="https://www.spicaai.com">www.spicaai.com</a></h6>
  </div>
  <div className="col-sm col-md- 6 col-lg-6">
    <h5><b>Sweeden</b></h5>
    <hr/>
    <h6>SPICA AI</h6>
    <h6>Triangeln 4</h6>
    <h6>211 43 Malmö</h6>
    <h6>CVR-nummer 38057561</h6>
    <br/>
    <p><b>Contact Information</b></p>
    <h6>Amar Al-Soudi</h6>
    <h6>amar@spicaai.com</h6>
    <h6>+45 52 81 95 53</h6>
    <h6><a href="https://www.linkedin.com/in/amar-al-soudi">LinkedIn</a></h6>
    <h6><a href="https://www.spicaai.com">www.spicaai.com</a></h6>
  </div>
      </div>
      <hr/>
      <br/>
      <div className="row">
      <div className="col-sm col-md-4 col-lg-4">
        
        <BusinessCard 
            name="Amar Al-Soudi" 
            //photo={amar} 
            title="Founder & Chief Technology Officer (CTO)" 
            resume="Experienced and highly skilled Senior IT Consultant with several years of expertise in delivering transformative technology solutions to clients,
             and implementing innovative systems to optimize business operations." 
             mobile="+45 52 81 95 53"
             flag={denmark}
             mail="amar@spicaai.com"
            id = "linjm"
            linkedin="https://www.linkedin.com/in/amar-al-soudi/"
          />
        </div>

        <div className="col-sm col-md-4 col-lg-4">
        <BusinessCard 
            name="Gabriel Balazs" 
            //photo={gabriel} 
            title="Development Manager" 
            resume="Highly skilled and motivated Senior Developer with several years of experience in designing, developing, and implementing software solutions,
            proven expertise in full-stack development to deliver high-quality applications." 
            mobile="+45 40 49 27 66"
            flag={denmark}
            mail= "gabriel@spicaai.com"
            id = "ijmlljk"
            linkedin="https://www.linkedin.com/in/gabrielbalazs/"
          />
        </div>

        
        <div className="col-sm col-md-4 col-lg-4">
          <BusinessCard 
            name="Chris Dyson" 
            //photo={chris}
            title="Data Privacy Officer (DPO)" 
            resume="Experienced Data Privacy Manager with a proven track record of safeguarding sensitive information and ensuring compliance with data protection regulations." 
            mobile="+44 7903 472810"
            flag={uk}
            mail= "chris@spicaai.com"
            id = "lijl"
            linkedin="https://www.linkedin.com/in/chris-dyson-638980161/"
          />
        </div>

        <div className="col-sm col-md-4 col-lg-4">
          <BusinessCard 
            name="Tivadar Balazs" 
            //photo={chris}
            title="Web Designer" 
            resume="Creative and detail-oriented web designer with a passion for crafting visually appealing, user-centric websites. 
            Proficient in industry-standard design tools and committed to delivering engaging digital experiences that meet clients' objectives" 
            mobile="+44 78085 60923"
            flag={uk}
            mail= "tivadar@spicaai.com"
            id = "5451"
            linkedin="https://www.linkedin.com/in/tivadar-balazs-91b78bb8/"
          />
        </div>
      </div>

</div>
  )
};

export default About;
