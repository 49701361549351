import React, { useEffect } from "react";
import homepage from '../img/webapps.png'
import apps from '../img/apps.jpg'
import arrow from '../img/arrow.png'
import arrow5 from '../img/arrow5.png'
import click from '../img/click.svg'
import checklist from '../img/checklist.svg'
import {Helmet} from "react-helmet";
import '../fonts.css'
import MarketingSlider from "./marketingSlider";
import bg5 from '../img/bg5.jpg'
import bg4 from '../img/bg4.jpg'
import Sliding from "./sliding";
import facebook from '../img/facebook2.svg'
import linkedin from '../img/linkedin2.svg'
import twitter from '../img/twitter2.svg'
import whatsapp from '../img/whatsapp2.svg'
import instagram2 from '../img/instagram2.svg'
import line from '../img/line.svg'
import trans from '../img/transformation.jpg'

import { FacebookShareButton, LinkedinShareButton, LineShareButton, WhatsappShareButton, TwitterShareButton } from 'react-share';


const Main = () => {

  return (<div>
     <div>
      <Helmet>
        <title>Spica AI</title>
        <meta name="description" content="software development company and ordering software for restaurants" />
        <meta name="keywords" content="UK, Sweden, Denmark" />
        <meta name="author" content="Amar Al-Soudi" />
        <meta property="og:title" content="Spica AI Home" />
        <meta property="og:description" content="order from restaurant with order ease software and software development company" />
        <meta property="og:image" content="https://example.com/image.jpg" />
        <meta property="og:url" content="https://example.com/my-page" />
        <meta name="twitter:title" content="Spica AI" />
        <meta name="twitter:description" content="This is a description of my page" />
        <meta name="twitter:image" content="https://example.com/image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
    </div>

    <br/>
    <br/> 

    <MarketingSlider />

    <div className="container-fluid px-4 p-md-5 pl-lg-5 mt-5 mt-md-0 mt-lg-0 pathway-extreme">
      <div className="row text-center justify-content-center rounded-5 p-lg-5" style={{borderBottomStyle:"solid", borderLeftStyle:"solid", borderRightStyle:"solid"}}>
        <div className="col-sm col-md-7 col-lg-7 align-self-center rounded-5 order-3 order-lg-1 order-md-1" data-aos="fade-up">
          <a style={{textDecoration:"none", color:"black"}} href="/digital-transformation">
            <p  style={{color:"#00394C", fontSize:"40px"}}>Digital <br/> Innovation</p>
            <h4>is not an upgrade, it's a revolution!</h4>
            <img  src={arrow} height="70" className="m-4"/>
          </a>
        </div>
        <div className="col-sm col-md-5 col-lg-5 rounded-5 order-1 order-lg-3 order-md-3" data-aos="fade-down">
          <br/>
          <img className="img-fluid rounded-5" src={trans} style={{boxShadow: "rgb(198,77,68, 0.74) 0px 22px 70px 4px"}}/>
          <br/>
          <br/>
        </div>
      </div>
    </div>
 
    <br/>
    <br/>


  </div>)
};

export default Main;
