import React from "react";
import '../pricing.css'
import arrow from '../img/arrow4.png'
import bg from '../img/pricingbg.jpg'


const Pricing = () => {
  return (<div>
    <br></br>
    <br></br>
    <br></br>
    <div className="container" style={{fontFamily: 'Josefin Slab, serif', fontSize:"1.2em"}}>
        <div className="row text-center">
            <div className="col-12 p-4" style={{fontSize:"1.8em"}}>
                <p>Choose the plan that fits your needs and take your online ordering to the next level!</p>
            </div>
        </div>

    <div className="row justify-content-center text-center">

        <div className="col-sm col-md-10 col-lg-4"  style={{marginBottom:"10px"}}>
          
          <div className=" p-5  h-100" style={{boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"}}>
            <p className="m-0" style={{fontSize:"40px", color:"#00394C"}}><b>Free</b></p> 
            <hr />

            <p style={{fontSize:"25px"}}>online ordering with a customizable menu</p>
            <p style={{fontSize:"25px"}}>integration with your new or existing website</p>
            <p style={{fontSize:"25px"}}>responsive ordering interface</p>
            <p style={{fontSize:"25px"}}>online payment</p>
            <p style={{fontSize:"25px"}}>order management dashboard</p>
            <p style={{fontSize:"25px"}}>notifications for new orders</p>
            <p style={{fontSize:"25px"}}>24/7 customer support via email</p>
            <br/>

            <p className="m-0" style={{fontSize:"20px"}}><b>only payment processing fee <br /> 4.9% + &#163;0.2 <br/> per order</b></p>
            <br></br>
            <a className="btn rounded-0" 
            style={{backgroundColor:"#00394C", color:"white", boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px"}}> <h4 className="pt-2">Pick up</h4> </a>
            <br/>
            <br/>
            <p style={{fontSize:"20px"}}>
              <b>If you are already enjoying this plan, and ready to take your experience to the next level, upgrading is very easy and few clicks away!</b>
            </p>
            </div>
        </div>

        <div className="col-sm col-md-10 col-lg-4" style={{marginBottom:"10px"}}>
          <div className="p-5 h-100" style={{ boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"}}>
           <p className="m-0" style={{fontSize:"40px", color:"#00394C"}}><b>Take More</b></p> 
           <hr />

            <p className="m-0" style={{fontSize:"20px"}}><b>All Free Package features plus</b></p>
            <br></br>
            
            <p style={{fontSize:"25px"}}>Online Booking</p>
            <a className="btn p-3" 
            style={{boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"  ,
            fontSize:"20px", color:"#21421e"}}><b>BookEase</b></a> 
            <br/>
            <br/>
            <p style={{fontSize:"25px"}}>QR code and digital menu</p>
            <a className="btn p-3" 
            style={{boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"  ,
            fontSize:"20px", color:"#21421e"}}><b>OrderEase QR</b></a> 
            <br/>
            <p className="p-2"><b>(for restaurants)</b></p>
            
            <p style={{fontSize:"25px"}}>Advanced analytics and reporting tool</p>
            <a className="btn p-3" 
            style={{boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"  ,
            fontSize:"20px", color:"#21421e"}}><b>OrderEase Analyse it</b></a> 
            <br/>
            <br/>
            <p style={{fontSize:"25px"}}>phone support during business hours</p>


            
            <span className="p-0" style={{fontSize:"40px", color:"#00394C"}}><b>&#163;199.99</b></span>
            <h4 className="p-0"><b>per month</b></h4>
          <p className="m-0" style={{fontSize:"20px"}}><b>plus payment processing fee <br/> 4.9% + &#163;0.2 <br/> per order</b></p>
          <br></br>
            
          <button className="btn rounded-0" 
            style={{backgroundColor:"#00394C", color:"white", boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px"}}> <h4 className="pt-2">Pick up or Upgrade</h4> </button>

            <p className="p-2"><b>(available soon!)</b></p>
            </div>
        </div>

        <div className="col-sm col-md-10 col-lg-4" style={{marginBottom:"10px"}}>
          <div className="p-5 h-100" style={{boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"}}>
           <p className="m-0" style={{fontSize:"40px", color:"#00394C"}}><b>Take It All</b></p> 
           <hr />
            <p className="m-0" style={{fontSize:"20px"}}><b>Take More plus</b></p>
            <br></br>

            <p style={{fontSize:"25px"}}><b>iOS and Andriod App with your own brand</b></p>
            <br/>
            <p style={{fontSize:"25px"}}>loyalty program</p>  
            <a className="btn p-3" 
            style={{boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" ,
            fontSize:"20px", color:"#21421e"}}><b>OrderEase Take Care</b></a> 
            <br/>
            <br/>
  
             <br/>
            <p style={{fontSize:"25px"}}><b>Third-Party Integrations</b></p>
            <p style={{fontSize:"25px"}}>24/7 priority support</p>


            <br/>
            <span className="p-0" style={{fontSize:"40px", color:"#00394C"}}><b>&#163;499.99</b></span>
            <h4 className="p-0"><b>per month</b></h4>
          <p className="m-0" style={{fontSize:"20px"}}><b>plus payment processing fee <br/> 4.9% + &#163;0.2 <br/> per order</b></p>
          <br></br>

            <button className="btn rounded-0" 
            style={{backgroundColor:"#00394C", color:"white", boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px"}}> <h4 className="pt-2">Pick up or Upgrade</h4> </button>

            <p className="p-2"><b>(available soon!)</b></p>

            <p><b>Every business is unique, and one size does not fit all, and that's why we offer further customization, so do not hasitate to contact us for special requets, that fits you unique business.
            </b></p>
            <a href="/contact" className="btn">
          <span  style={{fontFamily:"Roboto Slab, serif", fontSize:"20px"}}>contact</span>
          <img src={arrow} height="40" className="mb-2" />
        </a>
        </div>
</div>
       
    </div>

    </div>
    <br />
    <br />
    <br />
  </div>)
};

export default Pricing;
