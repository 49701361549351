import React from "react";
import plan from '../img/plan.jpg'
import booking from '../img/booking.png'
import reminders from '../img/reminders.jpg'
import invoice from '../img/invoice.jpg'
import document from '../img/ducoment.jpg'  


const ClinicFlow = () => {
  return (

<div className="text-center">    
<div className="text-center" >    
    <br></br>
    <br></br>
    <br></br>

<div className="container-fluid" >
<div className="row text-center">
      <div className="col-12 align-self-center p-0" >
        <p className="p-3" style={{fontFamily: 'Josefin Slab, serif', fontSize:"2em"}}>Interact with the system and explore its features in real-time</p>
        <a href="/register" 
          className="btn pt-3" 
          style={{fontFamily:"REM, sans-serif", color:"black", borderWidth:"1px", borderColor:"black", borderStyle:"solid"}}>
          <h3>Try ClinicFlow </h3>
        </a>
        <br></br>
        <br></br>
      </div>
  </div>

  <div className="row" style={{backgroundColor:"#E5E5E5"}}>
      <div  className="col-sm col-md-6 col-lg-6 align-self-center">
        <img src={plan} className=" img-fluid" />
      </div>
      <div className="col-sm col-md-6 col-lg-6 pt-lg-5 pt-md-5 align-self-center" >
        <p className="p-4" style={{fontFamily: 'Josefin Slab, serif', fontSize:"2em"}}>Enables healthcare providers to access comprehensive patient information promptly, leading to more informed and personalized care plans.</p>
      </div>
  </div>
</div>

<div className="container-fluid" >
  <div className="row p-2" style={{backgroundColor:""}}>
      <div className="col-sm col-md-6 col-lg-6 align-self-center p-0 " >
        <img src={booking} className="img-fluid" />
      </div>
      <div className="col-sm col-md-6 col-lg-6 pt-lg-5 pt-md-5 align-self-center p-0" >
        <p className="p-4" style={{fontFamily: 'Josefin Slab, serif', fontSize:"2em"}}>Allows staff to schedule and manage patient appointments, 
        reducing waiting times and optimizing healthcare provider schedules </p>
      </div>
  </div>
</div>

<div className="container-fluid" >
  <div className="row text-center" style={{backgroundColor:"#D0D0D0"}}>
      <div  className="col-sm col-md-6 col-lg-6 align-self-center p-0 " >
        <img src={invoice}  className="img-fluid" />
      </div>
      <div className="col-sm col-md-6 col-lg-6 pt-lg-5 pt-md-5 align-self-center p-0" >
      <p className="p-4" style={{fontFamily: 'Josefin Slab, serif', fontSize:"2em"}}> 
      Offers billing and invoicing capabilities to manage patient payments, insurance claims, and processing of medical bills</p>
      </div>
  </div>
</div>

<div className="container-fluid" >
  <div className="row text-center" style={{backgroundColor:"#BED3D8"}}>
      <div  className="col-sm col-md-6 col-lg-6 align-self-center p-0 " >
        <img src={document}  className="img-fluid" />
      </div>
      <div className="col-sm col-md-6 col-lg-6 pt-lg-5 pt-md-5 align-self-center p-0" >
      <p className="p-4" style={{fontFamily: 'Josefin Slab, serif', fontSize:"2em"}}> 
      Save and retrieve medical documents, reports, and other relevant files</p>
      </div>
  </div>
</div>

<div className="container-fluid" >
  <div className="row text-center" style={{backgroundColor:"#D5DDE8"}}>
      <div  className="col-sm col-md-6 col-lg-6 align-self-center p-0 " >
        <img src={reminders}  height="800" />
      </div>
      <div className="col-sm col-md-6 col-lg-6 pt-lg-5 pt-md-5 align-self-center p-0" >
      <p className="p-4" style={{fontFamily: 'Josefin Slab, serif', fontSize:"2em"}}> 
      Patients can receive automated reminders for upcoming appointments, reducing no-shows and improving appointment adherence, leading to better patient outcomes</p>
      </div>
  </div>
</div>

<br></br>
<div className="container-fluid" >

<div className="row text-center">
      <div className="col-12 align-self-center p-0" >
       <a href="/register" 
          className="btn pt-3 rounded" 
          style={{fontFamily:"REM, sans-serif", color:"black", borderWidth:"1px", borderColor:"black", borderStyle:"solid"}}>
          <h3>Sign Up </h3>
        </a>
        <br></br>
        <br></br>
      </div>
  </div>
  </div>




</div>


    </div>

  )
};

export default ClinicFlow;
