import React from "react";
import leo from '../img/leopard.jpg'
import flower from '../img/flower.jpg'
import cat from '../img/cat.jpg'
import SME from "./sme";
import trans from '../img/transformation.jpg'
import butterfly from '../img/butterfly.jpg'
import Big from "./big";
import Gaps from "./gaps";



const Why = () => {
  return (<div>

    <br/>
    <br/>
    <br/>
    <br/>

    <div className="container comfortaa">
    <div className="p-4">
        <div className="row rounded-5 p-3" style={{borderLeftStyle:"solid", borderRightStyle:"solid"}}>
          <div className="col-sm col-md-10 col-lg-10 comfortaa text-center align-self-center" data-aos="fade-up">
            <p className="responsive-font-bazar-intro">why?</p>
            <p className="responsive-font-why-content p-3">
              to <span style={{color:"#FFB902"}}><b>fill the gap</b></span> between the incredible potential of digital transformation and the struggle many organizations face in <span style={{color:"#FFB902"}}><b>understanding</b> </span>its true benefits
              </p>

          </div>
          <div className="col-sm col-md-2 col-lg-2 align-self-center p-md-0 p-lg-4"  data-aos="fade-down">
              <img src={butterfly} className="img-fluid rounded-5 " style={{boxShadow: "rgb(0,0,0) 0px 22px 70px 4px"}} />
          </div>
        </div>
      </div>

</div>

<div className="container">
  <div className="row justify-content-center">
    <div className="col-sm col-lg-4 col-md-6 text-center comfortaa rounded-5" style={{borderBottomStyle:"solid"}} data-aos="fade-down">
      <p className="p-3 responsive-font-why-1">The missing link is not the lack of technology! but a trusted partner who could illuminate the path to digital transformation</p>
    </div>
  </div>
</div>
<div className="container comfortaa p-4">

      <div className="row rounded-5 justify-content-center" style={{borderTopStyle:"solid", borderBottomStyle:"solid"}}>
      <div className="col-sm col-md-8 col-lg-4 text-center p-5 align-self-center"  data-aos="fade-down">
          <img src={cat} className="img-fluid rounded-5" style={{boxShadow: "rgb(0,0,0) 0px 22px 70px 4px"}} />
        </div>
        <div className="col-sm col-md-12 col-lg-8 text-center align-self-center"  data-aos="fade-up">
          <h3 className="pt-5">3 gaps to fill before going digital</h3>
          <Gaps />
          <br/>
          <br/>
        </div>
        
      </div>

    </div>
  </div>)
};

export default Why;
