import React from "react";
import arrow from '../img/arrow4.png'


const About = () => {
  return (<div>

<br></br>
<br></br>
<br></br>
<br></br>
<div className="container">
    <div className="row">
        <div className="col-12">
            <p style={{fontFamily:"REM, sans-serif", fontSize:"1.7em"}}>Executional User Experience and Performance Excellence</p>

            <p style={{fontFamily:"Roboto Slab, serif", fontSize:"1.2em"}}>
            We understand that user experience is paramount in today's digital landscape,
            our approach focuses on creating cloud software that's intuitive, user-friendly, and visually appealing.
            </p>

            <p style={{fontFamily:"Roboto Slab, serif", fontSize:"1.2em"}}>
            High performance is at the core of everything we create,
            we're dedicated to delivering software that's not just functional but performs optimally, even under demanding conditions.
            </p>

<img src={arrow} height="50px" className="mb-2" /><span style={{ fontSize:"1.2em", fontFamily:"Roboto Slab, serif"}}> User-Centric Design</span>
<br></br>
<img src={arrow} height="50px" className="mb-2" /><span style={{ fontSize:"1.2em", fontFamily:"Roboto Slab, serif"}}> Accessibility</span>
<br></br>

<img src={arrow} height="50px" className="mb-2" /><span style={{ fontSize:"1.2em", fontFamily:"Roboto Slab, serif"}}> Responsive Design</span>
<br></br>

<img src={arrow} height="50px" className="mb-2" /><span style={{ fontSize:"1.2em", fontFamily:"Roboto Slab, serif"}}> Speed and Efficiency</span>
<br></br>

<img src={arrow} height="50px" className="mb-2" /><span style={{ fontSize:"1.2em", fontFamily:"Roboto Slab, serif"}}> Scalability</span>
<br></br>

<img src={arrow} height="50px" className="mb-2" /><span style={{ fontSize:"1.2em", fontFamily:"Roboto Slab, serif"}}> Robust Testing</span>
<br></br>
<br></br>


        </div>
    </div>
</div>

<p>

</p>

  </div>)
};

export default About;
