import React from "react";
import '../product.css'

const ProductCard = (props) => {
  return (<div className="d-flex align-items-stretch">
 <section  class="wrapper d-flex align-items-stretch" style={{fontFamily: 'Josefin Slab, serif', fontSize:"1.2em"}}>
    <div class="container-fostrap">
        <div class={props.className} style={{height:"100%"}}>
            <a href={props.href}>
            <img src={props.image} className="img-fluid" />
            </a>
            <div class="card-content text-center" >
                <h4 class="card-title">
                    <a href="/#"> {props.name}</a>
                </h4>
                <p style={{fontSize:"20px"}}>{props.short}</p>
            </div>
            {props.showReadMoreButton &&
                <div class="card-read-more p-4">
                    <a href={props.href} class="btn" style={{backgroundColor:"#00394C", color:"white", boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px"}}>{props.buttonText}</a>
                </div>
            }
        </div>
    </div>

</section>

</div>)
};

export default ProductCard;
