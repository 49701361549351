import { useState, useRef, forwardRef } from "react";

import React from "react";
import logo from '../img/logo.png'
import person from '../img/person.png'
import denmark from '../images/dk.png'
import PhoneInput from 'react-phone-number-input'
import arrow from '../img/arrow4.png'
import mail from '../img/mail.png'
import facebook from '../img/facebook.png'
import linkedin from '../img/linkedin.svg'
import instagram from '../img/instagram.svg'
import bg from '../img/pricingbg.jpg'

import Contacts from './contacts'
import '../call.css'
import Call from "./call";
import CallMail from "./callMail";



const Contact = () => {

const[address, setAddress] = useState("");
const[name, setName] = useState("");
const[phone, setPhone] = useState("");

const[isNameMissing, setIsNameMissing] = useState(false);
const[isAddressMissing, setIsAddressMissing] = useState(false);
const[isPhoneMissing, setIsPhoneMissing] = useState(false);

const setPhoneNumber = () =>{
  ref.current.focus();  
  console.log(ref.current.value);
  //setPhone(ref.current.value);
}

const ref = useRef();

const phoneInput = forwardRef(({ value, onChange },  reff) => (
  <input id="inp" className="form-control rounded-0" value={value} style={{height:"50px", fontSize:"1.5em"}} ref = {reff}  onChange={onChange}></input>
));

function scrollToContact() {
  let e = document.getElementById("contact-form");
  e.scrollIntoView({
    block: 'start',
    behavior: 'smooth',
    inline: 'start'
  });
}

  return (<div>

  
<CallMail />

    <br></br>
    <br></br>
    <br></br>
    <br></br>

<div className="container">
  <div className="row" style={{fontFamily:"REM, sans-serif"}}>
    <div className="col-sm col-lg-8 col-md-8 text-center align-self-center">
      <p style={{fontSize:"40px"}}>Contact Us</p>
      <p style={{fontSize:"1.5em"}}>Have questions, need assistance, or interested in our software solutions? </p>
        <a href='tel:+4552819553'
            style={{fontSize:"20px", color:"white", backgroundColor:"#00394C", boxShadow: "rgb(38, 57, 77) 0px 20px 30px -5px"}}
            className="btn m-4 p-3 rounded-2">place a call</a>
    </div>
    <div className="col-sm col-lg-4 col-md-4">
     
      <div className="row p-4 text-center" style={{backgroundColor:"transparent"}}>
        <p style={{ fontSize:"1.3em"}}>SPICA AI</p> 
        <br></br>
        <p style={{fontFamily:"Roboto Slab, serif"}}>Triangeln 4, Malmö</p>

        <a href="https://vasakronan.se/arena/arena-triangeln/" className="btn">
          <span className="m-0" style={{fontFamily:"Roboto Slab, serif"}}>Arena Triangeln</span>
          <img src={arrow} height="30" />
        </a>
        <button className="btn" onClick={() => scrollToContact()}>
          <img src={mail} height="50" />
        </button>      
      </div>
    </div>
  </div>
  <br></br>
</div>






<div className="container-fluid">


<Contacts/>

    <div className="row">
      <div className="col-12 p-0">

        <iframe width="100%" 
      height="600"
       frameborder="0"
        marginheight="0"
       marginwidth="0" 
       src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Triangeln%204,%20Malm%C3%B6+(Avinya%20Solutions)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/population/">Find Population on Map</a></iframe></div>
      </div>

</div>


<div className="container"  id="contact-form">

<br></br>
<h1 style={{fontFamily:"REM, sans-serif", fontSize:"2em"}}> Contact Us</h1>

    <div className="row">
      <div className="col-md-6 col-lg-6 col-sm">
      <h5 className="pt-3" style={{fontFamily:"REM, sans-serif"}}>NAME</h5>

    <input type="text" style={{fontSize:"1.5em"}} className="form-control rounded-0"  onChange={event => setName(event.target.value)} />
    { isNameMissing && <h5 className="col alert alert-danger">name is missing </h5> }

      </div>
      <div className="col-md-6 col-lg-6 col-sm">
      <h5 style={{fontFamily:"REM, sans-serif"}} className="pt-3">COMPANY</h5>
    <input style={{fontSize:"1.5em"}} type="text" className="form-control rounded-0"  onChange={event => setAddress(event.target.value)} />
    { isAddressMissing && <h5 className="col alert alert-danger">address is missing </h5> }
      </div>
    </div>
    <div className="row">
      <div className="col-md-6 col-lg-6 col-sm">
      <h5 style={{fontFamily:"REM, sans-serif"}} className="pt-3">MAIL</h5>

    <input type="text" style={{fontSize:"1.5em"}} className="form-control rounded-0"  onChange={event => setName(event.target.value)} />
    { isNameMissing && <h5 className="col alert alert-danger m-1">name is missing </h5> }

      </div>
      <div className="col-md-6 col-lg-6 col-sm">
      <h5 className="pt-3" style={{fontFamily:"REM, sans-serif"}}>PHONE</h5>
      <PhoneInput 
    international
    inputComponent = {phoneInput}
    countryCallingCodeEditable={false}
    onCountryChange={(value) => {console.log(value)}}
    defaultCountry="GB"
    ref={ref}
    onChange={setPhoneNumber}/>
      </div>
    </div>
    <br></br>
    <h5 style={{fontFamily:"REM, sans-serif" }}>MESSAGE</h5>

    <textarea  type="text" className="form-control mb-2 p-3 rounded-0"  onChange={event => setAddress(event.target.value)} />
    { isAddressMissing && <h5 className="col alert alert-danger m-1">address is missing </h5> }

    <button  
    style={{fontFamily:"REM, sans-serif", color:"white", backgroundColor:"#00394C", boxShadow: "rgb(38, 57, 77) 0px 20px 30px -5px"}}
    className="btn mt-2 rounded-0"><h4 className="pt-2">SEND</h4></button>

  </div>

  <br/>

  </div>)
};

export default Contact;
