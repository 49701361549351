import React from "react";
import shopping from '../img/bags.jpg'
import eye from '../img/eye.jpg'
import marketing from '../img/marketing.jpg'
import onlineshop from '../img/online-shop.jpg'
import arrow from '../img/arrow4.png'
import '../orderease.css'
import bazar from '../img/bazar.jpg'


const OrderEase = () => {
  return (


<div className="text-center" >    
    <br></br>
    <br></br>


<div className="container-fluid" style={{fontFamily: 'Josefin Slab, serif'}}>
  <div className="row wrapper" style={{backgroundImage: `url(${shopping})`, backgroundSize:"cover", height:"500px", backgroundPosition:"55%"}}>
    <div className="overlay"></div>
    <div className="col-sm col-lg-6 col-md-6 align-self-center content">
      <br/>
      <p className="p-3 responsive-font-free content" style={{color:""}}><b>Revolutionize your business with BaZar included AI.
        <br/> Boost your online sales, all in one powerful solution </b></p>
      <p className="responsive-font-free content"><b>Try it today!</b></p>
      <a className="btn content" style={{backgroundColor:"#00394C", color:"white", boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px"}} href="/register"> <h4 className="pt-2"><b>Start Now</b></h4> </a>
    </div>
    <div className="col-sm col-lg-6 col-md-6"></div>
  </div>

<br/>
<br/>


  <div className="row p-3 justify-content-center">
      <div  className="col-sm col-md-3 col-lg-3 align-self-center p-lg-5 p-md-5">
        <img src={bazar} className=" rounded-5 img-fluid" style={{boxShadow: "rgba(245, 203, 66) 0px 22px 70px 4px"}} />
      </div>
      <div className="col-sm col-md-7 col-lg-7 pt-lg-5 pt-md-5 align-self-center p-5" >
        <div className="row">
          <div className="col-12 rounded-5 p-5" style={{borderStyle:"solid", borderColor:"#f5cb42", borderLeftWidth:"0px"}}>
          <p className="responsive-font-orderease" style={{fontFamily: 'Josefin Slab, serif'}}>Build your online store with <a href="/#" style={{color:"#f5cb42"}} className="comfortaa">BaZar</a> and start your online business today! </p>
          <a href="/order-ease-shop"> <img className="" src={arrow} height="70" /></a>
          </div>
        </div>

      </div>
  </div>


  <div className="row justify-content-center p-3">
      <div className="col-sm col-md-7 col-lg-7 pt-lg-5 pt-md-5 align-self-center p-5 order-2 order-lg-1 order-md-1" >
        <div className="row">
          <div className="col-12 rounded-5 p-5" style={{borderStyle:"solid", borderColor:"#760b41", borderRightWidth:"0px"}}>
            <p className="responsive-font-orderease" style={{fontFamily: 'Josefin Slab, serif'}}><a href="/#" style={{color:"#760b41"}}><b>The eYe</b></a> is an AI-driven analysis tool to unlock hidden patterns, trends, and valuable insights from your data.
            </p>
            <img className="" src={arrow} height="70" />
          </div>
        </div>
      </div>

      <div  className="col-sm col-md-5 col-lg-3 align-self-center p-lg-5 p-md-5 order-1 order-lg-2 order-md-2">
        <img src={eye} className=" rounded-5 img-fluid" style={{boxShadow: "rgba(118, 11, 65) 0px 22px 70px 4px"}} />
      </div>
  </div>

  <div className="row justify-content-center p-3">
  <div  className="col-sm col-md-5 col-lg-3 align-self-center p-lg-5 p-md-5">
        <img src={marketing} className=" rounded-5 img-fluid" style={{boxShadow: "rgba(4, 87, 79) 0px 22px 70px 4px"}} />
      </div>
      <div className="col-sm col-md-7 col-lg-7 pt-lg-5 pt-md-5 align-self-center p-5" >
        <div className="row">
          <div className="col-12 rounded-5 p-5" style={{borderStyle:"solid", borderColor:"#04574f", borderLeftWidth:"0px"}}>
            <p className="responsive-font-orderease" style={{fontFamily: 'Josefin Slab, serif'}}>

            Unlock the future of marketing with OrderEase <a href="/#" style={{color:"#04574f"}}><b> Take Care</b></a> a cutting-edge AI-powered tool, designed to connect you directly with your target audience 
            </p>
            <img className="" src={arrow} height="70" />
          </div>
        </div>
      </div>
  </div>
</div>
</div>

  )
};

export default OrderEase;
