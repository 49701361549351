
//FOR LOCAL TEST USE

/*
export const GET_CATEGORIES_URL = 'https://localhost:7088/category/get-categories?clientId='
export const PLACE_ORDER_URL = 'https://localhost:7088/order/place-order'
export const GET_PRODUCTS_BY_CATEGORY_URL = 'https://localhost:7088/product/get-products-by-category?categoryId='
export const GET_CATEGORIES_WITH_PRODUCTS_URL = 'https://localhost:7088/category/get-categories-with-products?clientId='
export const CREATE_PURCHASE_URL = 'https://localhost:7088/payment/create-purchase'
export const CLIENT_ID = "D13C6651-B5B5-4F14-A122-9D17FEE30FB8"
export const PAYMENT_ORDER_URL = 'https://localhost:7088/payment/payment-order'
export const UPDATE_ORDER_URL = 'https://localhost:7088/payment/update-order'
export const CAPTURE_PAYMENT_URL = 'https://localhost:7088/payment/capture-payment'
export const ONBOARDING_URL = 'https://localhost:7088/onboarding/takeoff'
export const CREATE_CUSTOMER_URL = 'https://localhost:7088/onboarding/create-customer'

export const ONBOARDING_REFRESH_URL = 'localhost:3000/onboarding-refresh'
export const ONBOARDING_RETURN_URL = 'localhost:3000/onboarding-completed'
export const ENVIROMENT = "LOCAL"
*/

//dev
export const GET_CATEGORIES_URL = 'https://avinya-webshop-admin-api.azurewebsites.net/category/get-categories?clientId='
export const PLACE_ORDER_URL = 'https://avinya-webshop-admin-api.azurewebsites.net/order/place-order'
export const GET_PRODUCTS_BY_CATEGORY_URL = 'https://avinya-webshop-admin-api.azurewebsites.net/product/get-products-by-category?categoryId='
export const GET_CATEGORIES_WITH_PRODUCTS_URL = 'https://avinya-webshop-admin-api.azurewebsites.net/category/get-categories-with-products?clientId='
export const CREATE_PURCHASE_URL = 'https://avinya-webshop-admin-api.azurewebsites.net/payment/create-purchase'
export const CLIENT_ID = "D13C6651-B5B5-4F14-A122-9D17FEE30FB8"
export const PAYMENT_ORDER_URL = 'https://avinya-webshop-admin-api.azurewebsites.net/payment/payment-order'
export const UPDATE_ORDER_URL = 'https://avinya-webshop-admin-api.azurewebsites.net/payment/update-order'
export const CAPTURE_PAYMENT_URL = 'https://avinya-webshop-admin-api.azurewebsites.net/payment/capture-payment'
export const ONBOARDING_URL = 'https://avinya-webshop-admin-api.azurewebsites.net/onboarding/takeoff'
export const CREATE_CUSTOMER_URL = 'https://avinya-webshop-admin-api.azurewebsites.net/onboarding/create-customer'

export const ONBOARDING_REFRESH_URL = 'https://avinya-dev.azurewebsites.net/onboarding-refresh'
export const ONBOARDING_RETURN_URL = 'https://avinya-dev.azurewebsites.net/onboarding-completed'

export const ENVIROMENT = "DEV"



