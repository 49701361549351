import React from "react";
import ProductCard from "./productCard";
import booking from '../img/booking.jpg'
import order from '../img/order.jpg'
import clinic from '../img/clinicflow.jpg'
import bg from '../img/pricingbg.jpg'


const Products = () => {
  return (<div>

<br></br>
<br></br>
<br></br>



<div className="container">
  <div className="row text-center">
    <div className="col-12 p-4" style={{fontFamily: 'Josefin Slab, serif', fontSize:"2em"}}>
      <p>Our products are designed with end-user in mind, focusing on ease of use and an exceptional user experience</p>
    </div>
  </div>
  <div className="row justify-content-center ">
    
      <div className="col-sm col-lg-4 col-md-6 d-flex align-items-stretch">
      <ProductCard image={order} 
      name="OrderEase" 
      className = "card"
      href="/orderease"
      buttonText="Read More"
      short="From a centralized dashboard, oversee incoming orders, track fulfillment, manage inventory, and streamline communication with customers."/>
      </div>  

      <div className="col-sm col-lg-4 col-md-6 d-flex align-items-stretch">
        <ProductCard image={booking} name="BookEase" 
        href="#"
        className = "card"
        buttonText="Read More"
         short="Whether you are booking a service, reserving a table, or scheduling an event, our streamlined interface ensures a hassle-free experience." />
      </div>  

      <div className="col-sm col-lg-4 col-md-6 d-flex align-items-stretch">
        <ProductCard image={clinic} 
        name="ClinicFlow" 
        href="#"
        className = "card"
        buttonText="available 2024"
        bottomSpace = {<br/>}
        short="Simplify and optimize every aspect of your clinic's operations, providing a seamless experience for both your staff and patients." />
      </div>  
  </div>
</div>


<br></br>
    <br></br>
    <br></br>

  </div>)
};

export default Products;
