import React, { useEffect } from "react";
import arrow from '../img/arrow.png'
import next from '../img/next.svg'
import prev from '../img/prev.svg'
import eye from '../img/eye.jpg'
import marketing from '../img/marketing.jpg'
import bazar from '../img/bazar.jpg'
import "bootstrap/js/src/carousel.js";
import odalis from '../img/odalis.jpg'

import '../fonts.css'
import '../main.css'


const MarketingSlider = () => {


useEffect(() => {
  window.init();
})

  return (<div className="slider-height">

<div id="carouselExampleAutoplaying" class="carousel slide pathway-extreme slider-height" data-bs-ride="carousel">
<div className="carousel-inner slider-img-padding slider-img-padding-top p-ipad slider-height">


<div className="carousel-item active" data-bs-interval="3000" >
    <div className="container">
      <div className="row text-center justify-content-center">
        <div className="col-sm col-md-3 col-lg-3">
          <img src={marketing} className="rounded-5 img-fluid" style={{boxShadow: "rgba(4, 87, 79) 0px 22px 70px 4px"}}/>
        </div>
        <div className="col-sm col-md-7 col-lg-7 align-self-center pt-4" style={{color:"black"}}>
          <p className="responsive-font-marketing-slider-titles comfortaa" style={{color:"#02584F"}}>Hannibal</p>
          <p className="responsive-font-marketing-slider-content p-3">Hanibal connects you to your target audience</p>
          <a href="/orderease"><img src={arrow} height="70" /></a>
        </div>
      </div>
    </div>
  </div>


<div className="carousel-item " data-bs-interval="3000">
    <div className="container">
      <div className="row text-center justify-content-center">
        <div className="col-sm col-md-3 col-lg-3">
          <img src={bazar} className="rounded-5 img-fluid" style={{boxShadow: "rgba(244,160,70) 0px 22px 70px 4px"}} />
        </div>
        <div className="col-sm col-md-7 col-lg-7 align-self-center pt-4 pathway-extreme">
          <p className="responsive-font-marketing-slider-titles comfortaa" style={{color:"#F09C43"}}>baZar</p>
          <p className="responsive-font-marketing-slider-content p-3">Showcase your products and start your <span className="comfortaa">baZar</span> Today! </p>
          <a href="/bazar"><img src={arrow} height="70" /></a>
        </div>
      </div>
    </div> 
  </div>

  <div className="carousel-item" data-bs-interval="3000">
    <div className="container">
      <div className="row text-center justify-content-center">
        <div className="col-sm col-md-3 col-lg-3">
          <img src={odalis} className="rounded-5 img-fluid" style={{boxShadow: "rgba(244,160,70) 0px 22px 70px 4px"}} />
        </div>
        <div className="col-sm col-md-7 col-lg-7 align-self-center pt-4 pathway-extreme">
          <p className="responsive-font-marketing-slider-titles comfortaa" style={{color:"#033B3C"}}>Odalis</p>
          <p className="responsive-font-marketing-slider-content p-3">Odalis take care of customers when they visit your baZar </p>
          <a href="/orderease"><img src={arrow} height="70" /></a>
        </div>
      </div>
    </div> 
  </div>

  <div className="carousel-item" data-bs-interval="3000">
    <div className="container">
      <div className="row text-center justify-content-center">
        <div className="col-sm col-md-3 col-lg-3 align-self-center">
          <img src={eye} className="rounded-5 img-fluid" style={{boxShadow: "rgba(118, 11, 65) 0px 22px 70px 4px"}}/>
        </div>
        <div className="col-sm col-md-7 col-lg-7 align-self-center pt-4" style={{color:"black"}}>
          <p className="responsive-font-marketing-slider-titles comfortaa" style={{color:"#760b41"}}>the eYe</p>
          <p className="responsive-font-marketing-slider-content p-3">the eYe will look deep and get insights into your sales and trends </p>
          <a href="/orderease"><img src={arrow} height="70" /></a>
        </div>
      </div>
    </div>
  </div>
      


  




      </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
    <img src={prev} height="60px" />
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
  <img src={next} height="60px" />

  </button>
</div>



  </div>)
};


export default MarketingSlider;
